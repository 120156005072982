import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

import Header from './components/Header';
import ContactPage from './components/ContactPage';
import Services from './components/Services';
import About from './components/About';
import HomePage from './components/HomePage';
import Demat from './components/Demat';
import Syllabus from './components/Syllabus';
import StudyOptions from './components/StudyOptions';
import Testimonials from './components/Testimonials';
import WhatsAppButton from './components/WhatsAppButton';
import CourseDetail from './components/CourseDetail';
import OptionsCalculator from './components/FeaturesServices/OptionsCalculator';


const App = () => {
  return (
    <Router>
      <Header/>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/Contact" element={<ContactPage />} />
        <Route path="/services" element={<Services />} />
        <Route path="/Header" element={<Header />} />
        <Route path="/About" element={<About />} />
        <Route path="/Syllabus" element={<Syllabus />} />
        <Route path="/StudyOptions" element={<StudyOptions />} />
        <Route path="/Testimonials" element={<Testimonials />} />
        <Route path="/open-demat" element={<Demat />} />
        <Route path="/course/:id" element={<CourseDetail />} />
        <Route path="/OptionsCalculator" element={<OptionsCalculator />} />
      </Routes>
      <WhatsAppButton/>
    </Router>
  );
};

const container = document.getElementById('app');
const root = createRoot(container);
root.render(<App />);