import React, { useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { ArrowLeft, Clock, BarChart2, Users, Star, BookOpen, ChevronDown, ChevronUp } from 'lucide-react';
import './CourseDetail.css';
import founderImage from "../Images/shivam.jpg";

const CourseDetail = () => {
  const { id } = useParams();
  const [activeSection, setActiveSection] = useState('overview');
  const [expandedFaqs, setExpandedFaqs] = useState([]);
  const navigate = useNavigate();
  // Updated courses data
  const courses = [
    {
      id: 0,
      title: 'Stock Trading Fundamentals',
      price: '₹199',
      duration: '4 weeks',
      level: 'Beginner',
      color: '#FF6B6B',
      description: 'Learn the basics of stock trading, including market analysis, order types, and risk management strategies.',
      topics: [
        'Introduction to Stock Markets', 
        'Reading Financial Statements', 
        'Basic Technical Analysis', 
        'Fundamental Analysis', 
        'Risk Management'
      ],
      instructor: {
        name: 'Shiivam Rahhar Rahhar',
        bio: 'Shiivam Rahhar is a seasoned stock trader with 6 years of experience in the Indian stock market. He has a knack for simplifying complex concepts for beginners.',
        image: founderImage
      },
      rating: 4.8,
      enrolledStudents: 1200,
      learningObjectives: [
        'Understand the basics of how stock markets function',
        'Learn to read and interpret financial statements',
        'Develop a foundation in technical and fundamental analysis',
        'Create a personalized risk management strategy'
      ],
      faqs: [
        { q: 'Is this course suitable for absolute beginners?', a: 'Yes, this course is designed specifically for those new to stock trading.' },
        { q: 'Will I need any special software for this course?', a: 'No, all you need is a computer with internet access. We\'ll introduce you to free online tools during the course.' },
        { q: 'How much time should I dedicate to this course each week?', a: 'We recommend setting aside 4-6 hours per week for the best learning experience.' }
      ]
    },
    {
      id: 1,
      title: 'Advanced Technical Analysis',
      price: '₹299',
      duration: '6 weeks',
      level: 'Intermediate',
      color: '#4ECDC4',
      description: 'Master advanced technical analysis techniques to improve your trading strategies and market predictions.',
      topics: [
        'Advanced Chart Patterns',
        'Fibonacci Retracements and Extensions',
        'Elliott Wave Theory',
        'Volume Analysis',
        'Intermarket Analysis'
      ],
      instructor: {
        name: 'Shiivam Rahhar Rahhar',
        bio: 'Shiivam Rahhar is an experienced trader with 6 years of expertise in the Indian stock market. He excels at breaking down complex technical analysis concepts for intermediate learners.',
        image: founderImage
      },
      rating: 4.9,
      enrolledStudents: 850,
      learningObjectives: [
        'Identify and interpret complex chart patterns',
        'Apply Fibonacci tools to predict support and resistance levels',
        'Understand and use Elliott Wave Theory for market forecasting',
        'Incorporate volume analysis into your trading decisions',
        'Analyze relationships between different markets for a holistic trading approach'
      ],
      faqs: [
        { q: 'What prior knowledge is required for this course?', a: 'Students should have a basic understanding of technical analysis and charting. Our "Stock Trading Fundamentals" course is a good prerequisite.' },
        { q: 'Will this course cover specific trading software?', a: 'Yes, we\'ll introduce you to popular charting software and teach you how to use advanced features for technical analysis.' },
        { q: 'How can I apply these techniques to the Indian market?', a: 'The course includes specific examples and case studies from the Indian stock market to help you apply these techniques locally.' }
      ]
    },
    {
      id: 2,
      title: 'Options Trading Mastery',
      price: '₹399',
      duration: '8 weeks',
      level: 'Advanced',
      color: '#FF9F1C',
      description: 'Dive deep into the world of options trading, learning advanced strategies and risk management techniques.',
      topics: [
        'Options Basics and Terminology',
        'Advanced Options Strategies',
        'Greeks and Their Impact',
        'Volatility Trading',
        'Options Risk Management'
      ],
      instructor: {
        name: 'Shiivam Rahhar Rahhar',
        bio: 'Shiivam Rahhar is an options trading expert with 6 years of experience. He focuses on educating traders about the intricacies of options and advanced trading strategies.',
        image: founderImage
      },
      rating: 4.7,
      enrolledStudents: 620,
      learningObjectives: [
        'Understand the mechanics of options and option pricing',
        'Master complex options strategies for various market conditions',
        'Learn to use and interpret option Greeks',
        'Develop strategies for trading volatility',
        'Create a comprehensive risk management plan for options trading'
      ],
      faqs: [
        { q: 'Is this course suitable for stock traders new to options?', a: 'While we cover some basics, this course is designed for traders who already have some experience with options. We recommend having a solid understanding of stock trading before enrolling.' },
        { q: 'Will we use real market data in this course?', a: 'Yes, we use real-time and historical market data to provide practical, hands-on learning experiences.' },
        { q: 'Are there any additional costs for tools or software?', a: 'We provide access to an options analysis platform for the duration of the course. After the course, you can choose to subscribe or use alternative tools.' }
      ]
    },
    {
      id: 3,
      title: 'Algorithmic Trading',
      price: '₹499',
      duration: '10 weeks',
      level: 'Expert',
      color: '#A06CD5',
      description: 'Learn to design, develop, and implement automated trading strategies using advanced programming techniques.',
      topics: [
        'Introduction to Algorithmic Trading',
        'Python for Trading',
        'Quantitative Analysis',
        'Building Trading Algorithms',
        'Backtesting and Optimization',
        'Machine Learning in Trading'
      ],
      instructor: {
        name: 'Shiivam Rahhar Rahhar',
        bio: 'Shiivam Rahhar has been developing trading algorithms for 6 years. He now shares his expertise in quantitative analysis and algorithmic trading with aspiring algo traders.',
        image: founderImage
      },
      rating: 4.9,
      enrolledStudents: 450,
      learningObjectives: [
        'Understand the principles and practices of algorithmic trading',
        'Develop proficiency in Python for financial data analysis and trading',
        'Learn to design and implement various trading algorithms',
        'Master the techniques of backtesting and optimizing trading strategies',
        'Explore the application of machine learning in predictive modeling for trading'
      ],
      faqs: [
        { q: 'What programming experience is required for this course?', a: 'Intermediate proficiency in Python is required. We also recommend basic knowledge of statistics and financial markets.' },
        { q: 'Will this course teach me how to create a fully automated trading system?', a: 'Yes, by the end of the course, you will have the skills to develop, test, and potentially deploy your own algorithmic trading systems.' },
        { q: 'Are there any legal considerations for algo trading that this course covers?', a: 'We discuss regulatory aspects and best practices for algorithmic trading, but we recommend consulting with legal experts before deploying any trading system.' }
      ]
    }
  ];

  const course = courses[parseInt(id)];

  if (!course) {
    return <div className="course-not-found">Course not found</div>;
  }

  const toggleFaq = (index) => {
    setExpandedFaqs(prev => 
      prev.includes(index) ? prev.filter(i => i !== index) : [...prev, index]
    );
  };
  const handleEnroll = () => {
    
    const message = `Hi Sir, I am interested in enrolling in your ${course.title} course.`;
    const whatsappUrl = `https://wa.me/${+917740047670}?text=${encodeURIComponent(message)}`;
    
    // Navigate to the Contact page
    navigate('/Contact');
    
    // Open WhatsApp in a new tab
    window.open(whatsappUrl, '_blank');
  };

  return (
    <div className="course-detail-container">
      <Link to="/Syllabus" className="back-link">
        <ArrowLeft size={20} />
        Back to Courses
      </Link>
      
      <div className="course-header" style={{ backgroundColor: course.color }}>
        <h1>{course.title}</h1>
        <p>{course.description}</p>
      </div>
      
      <div className="course-content">
        <div className="course-sidebar">
          <div className="course-meta">
            <div><Clock size={20} /> {course.duration}</div>
            <div><BarChart2 size={20} /> {course.level}</div>
            <div><Users size={20} /> {course.enrolledStudents} students</div>
            <div><Star size={20} /> {course.rating} rating</div>
          </div>
          <div className="course-cta">
            <button className="enroll-button"  onClick={handleEnroll}>Enroll Now</button>
          </div>
        </div>
        
        <div className="course-main">
          <div className="course-tabs">
            {['overview', 'instructor', 'faq'].map(section => (
              <button 
                key={section}
                className={activeSection === section ? 'active' : ''}
                onClick={() => setActiveSection(section)}
              >
                {section.charAt(0).toUpperCase() + section.slice(1)}
              </button>
            ))}
          </div>
          
          {activeSection === 'overview' && (
            <div className="course-overview">
              <h2>What you'll learn</h2>
              <ul className="learning-objectives">
                {course.learningObjectives.map((objective, index) => (
                  <li key={index}><BookOpen size={16} /> {objective}</li>
                ))}
              </ul>
              <h2>Course Content</h2>
              <ul className="course-topics">
                {course.topics.map((topic, index) => (
                  <li key={index}>{topic}</li>
                ))}
              </ul>
            </div>
          )}
          
          {activeSection === 'instructor' && (
            <div className="instructor-info">
              <div className="instructor-image-container">
                <img src={course.instructor.image} alt={course.instructor.name} className="instructor-image" />
              </div>
              <h2>{course.instructor.name}</h2>
              <p>{course.instructor.bio}</p>
            </div>
          )}
          
          {activeSection === 'faq' && (
            <div className="faq-section">
              <h2>Frequently Asked Questions</h2>
              {course.faqs.map((faq, index) => (
                <div key={index} className="faq-item">
                  <button className="faq-question" onClick={() => toggleFaq(index)}>
                    {faq.q}
                    {expandedFaqs.includes(index) ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                  </button>
                  {expandedFaqs.includes(index) && <p className="faq-answer">{faq.a}</p>}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CourseDetail;