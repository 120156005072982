import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import './StockMarketLearningComparison.css';

const StockMarketLearningComparison = () => {
  const data = [
    { category: 'Potential Returns', Learned: 8, 'Did Not Learn': 3 },
    { category: 'Risk Management', Learned: 7, 'Did Not Learn': 2 },
    { category: 'Diversification', Learned: 9, 'Did Not Learn': 4 },
    { category: 'Long-term Growth', Learned: 8, 'Did Not Learn': 5 },
    { category: 'Financial Understanding', Learned: 9, 'Did Not Learn': 3 },
  ];

  return (
    <div className="stock-market-comparison">
      <h2>The Power of Stock Market Knowledge</h2>
      <div className="chart-container">
        <ResponsiveContainer width="100%" height={window.innerWidth < 768 ? 300 : 400}>
          <BarChart
            data={data}
            layout={window.innerWidth < 768 ? 'vertical' : 'horizontal'}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type={window.innerWidth < 768 ? 'number' : 'category'} dataKey={window.innerWidth < 768 ? null : 'category'} />
            <YAxis type={window.innerWidth < 768 ? 'category' : 'number'} dataKey={window.innerWidth < 768 ? 'category' : null} />
            <Tooltip />
            <Legend />
            <Bar dataKey="Learned" fill="#4CAF50" />
            <Bar dataKey="Did Not Learn" fill="#FFA726" />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div className="impact-description">
        <h3>Don't Miss Out on Financial Growth!</h3>
        <p>
          This chart illustrates the dramatic impact of stock market education. Those who invest in learning consistently outperform in key areas:
        </p>
        <ul>
          <li><strong>Potential Returns:</strong> Educated investors spot lucrative opportunities others miss.</li>
          <li><strong>Risk Management:</strong> Knowledge is your shield against market volatility.</li>
          <li><strong>Diversification:</strong> Learn to spread your investments wisely for optimal growth.</li>
          <li><strong>Long-term Growth:</strong> Understand the power of compound interest and patience.</li>
          <li><strong>Financial Understanding:</strong> Gain confidence in your financial decisions.</li>
        </ul>
        <p className="cta1">
          Don't let others get ahead while you fall behind. Start your stock market education journey today and unlock your financial potential!
        </p>
      </div>
    </div>
  );
};

export default StockMarketLearningComparison;
