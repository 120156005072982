import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import './FeaturedCourses.css';

const FeaturedCourses = () => {
  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
  };

  const courses = [
    {
      title: 'Stock Trading Fundamentals',
      price: '₹199', // Changed to INR as per previous discussions
      duration: '4 weeks',
      level: 'Beginner',
      color: '#FF6B6B',
    },
    {
      title: 'Advanced Technical Analysis',
      price: '₹299',
      duration: '6 weeks',
      level: 'Intermediate',
      color: '#4ECDC4',
    },
    {
      title: 'Options Trading Mastery',
      price: '₹399',
      duration: '8 weeks',
      level: 'Advanced',
      color: '#45B7D1',
    },
    {
      title: 'Algorithmic Trading',
      price: '₹499',
      duration: '10 weeks',
      level: 'Expert',
      color: '#F7B801',
    },
  ];

  return (
    <motion.section
      className="featured-courses" // Corrected class name
      initial="hidden"
      animate="visible"
      variants={fadeInUp}
    >
      <h2>Featured Courses</h2>
      <div className="course-grid">
        {courses.map((course, index) => (
          <motion.div
            key={index}
            className="course-item"
            whileHover={{ scale: 1.03, boxShadow: '0 8px 16px rgba(0,0,0,0.2)' }}
            style={{ backgroundColor: course.color }}
          >
            <h3>{course.title}</h3>
            {/* <p className="price">{course.price}</p> */}
            <p>Duration: {course.duration}</p>
            <p>Level: {course.level}</p>
            <Link to={`/course/${index}`} className="course-button">
              Learn More
            </Link>
          </motion.div>
        ))}
      </div>
      <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
     {/*    <Link to="/courses" className="view-all-button">
          View All Courses
        </Link> */}
      </motion.div>
    </motion.section>
  );
};

export default FeaturedCourses;
