// Advanced JavaScript for Options Calculator (No external dependencies)

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine, ResponsiveContainer } from 'recharts';
import { ArrowUpCircle, ArrowDownCircle, IndianRupee, Percent, Calendar } from 'lucide-react';
import "../FeaturesServices/OptionsCalculator.css"
// Constants
const DAYS_IN_YEAR = 365;
const RISK_FREE_RATE = 0.02; // 2% risk-free rate, adjust as needed

// Simplified Black-Scholes calculation
const calculateBlackScholes = (type, S, K, T, r, v) => {
  const d1 = (Math.log(S / K) + (r + v * v / 2) * T) / (v * Math.sqrt(T));
  const d2 = d1 - v * Math.sqrt(T);
  
  const cdf = (x) => {
    let t = 1 / (1 + 0.2316419 * Math.abs(x));
    let d = 0.3989423 * Math.exp(-x * x / 2);
    let p = d * t * (0.3193815 + t * (-0.3565638 + t * (1.781478 + t * (-1.821256 + t * 1.330274))));
    if (x > 0) p = 1 - p;
    return p;
  };

  if (type === 'call') {
    return S * cdf(d1) - K * Math.exp(-r * T) * cdf(d2);
  } else {
    return K * Math.exp(-r * T) * cdf(-d2) - S * cdf(-d1);
  }
};

// Custom hook for option calculations
const useOptionCalculator = (initialState) => {
  const [state, setState] = useState(initialState);
  const [results, setResults] = useState({
    breakeven: 0,
    maxProfit: 0,
    maxLoss: 0,
    timeValue: 0,
    intrinsicValue: 0,
    optionPrice: 0,
  });

  const calculateOption = useCallback(() => {
    const {
      optionType,
      stockPrice,
      strikePrice,
      premium,
      expiration,
      volatility,
    } = state;

    // Calculate option price using simplified Black-Scholes
    const timeToExpiration = expiration / DAYS_IN_YEAR;
    const optionPrice = calculateBlackScholes(
      optionType,
      stockPrice,
      strikePrice,
      timeToExpiration,
      RISK_FREE_RATE,
      volatility / 100
    );

    // Calculate breakeven point
    const breakeven =
      optionType === 'call'
        ? strikePrice + premium
        : strikePrice - premium;

    // Calculate max profit and max loss
    const maxProfit =
      optionType === 'call'
        ? 'Unlimited'
        : (strikePrice - premium).toFixed(2);
    const maxLoss = premium.toFixed(2);

    // Calculate time value and intrinsic value
    const intrinsicValue = Math.max(0, optionType === 'call' ? stockPrice - strikePrice : strikePrice - stockPrice);
    const timeValue = (premium - intrinsicValue).toFixed(2);

    setResults({
      breakeven: breakeven.toFixed(2),
      maxProfit,
      maxLoss,
      timeValue,
      intrinsicValue: intrinsicValue.toFixed(2),
      optionPrice: optionPrice.toFixed(2),
    });
  }, [state]);

  useEffect(() => {
    calculateOption();
  }, [calculateOption]);

  return [state, setState, results];
};

// Utility function to generate chart data
const generateChartData = (state, results) => {
  const { optionType, stockPrice, strikePrice, premium } = state;
  const { breakeven } = results;
  const data = [];
  const range = stockPrice * 0.5;

  for (let price = stockPrice - range; price <= stockPrice + range; price += range / 50) {
    let profit;
    if (optionType === 'call') {
      profit = Math.max(0, price - strikePrice) - premium;
    } else {
      profit = Math.max(0, strikePrice - price) - premium;
    }
    data.push({ price: price.toFixed(2), profit: profit.toFixed(2) });
  }

  return data;
};

// Custom hook for handling chart interactions
const useChartInteractions = (chartRef) => {
  const [hoveredPoint, setHoveredPoint] = useState(null);

  useEffect(() => {
    const chart = chartRef.current;
    if (!chart) return;

    const handleMouseMove = (event) => {
      const tooltipData = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, false);
      if (tooltipData.length > 0) {
        const dataPoint = tooltipData[0];
        setHoveredPoint(dataPoint);
      } else {
        setHoveredPoint(null);
      }
    };

    chart.canvas.addEventListener('mousemove', handleMouseMove);

    return () => {
      chart.canvas.removeEventListener('mousemove', handleMouseMove);
    };
  }, [chartRef]);

  return hoveredPoint;
};

// Component for displaying option strategy explanation
const OptionStrategyExplanation = ({ optionType, stockPrice, strikePrice }) => {
  const strategy = optionType === 'call' ? 'Call Option' : 'Put Option';
  const action = optionType === 'call' ? 'buy' : 'sell';
  const direction = optionType === 'call' ? 'rise' : 'fall';

  return (
    <div className="strategy-explanation">
      <h3>{strategy} Strategy</h3>
      <p>
        You are buying a {strategy.toLowerCase()} with a strike price of ${strikePrice}. 
        This strategy is typically used when you expect the stock price to {direction}.
      </p>
      <p>
        If the stock price is ${stockPrice} at expiration:
      </p>
      <ul>
        {optionType === 'call' ? (
          <>
            <li>If the stock price is above ${strikePrice}, you'll profit from the difference.</li>
            <li>If the stock price is below ${strikePrice}, your loss is limited to the premium paid.</li>
          </>
        ) : (
          <>
            <li>If the stock price is below ${strikePrice}, you'll profit from the difference.</li>
            <li>If the stock price is above ${strikePrice}, your loss is limited to the premium paid.</li>
          </>
        )}
      </ul>
    </div>
  );
};

// Main Options Calculator component
const OptionsCalculator = () => {
  const [state, setState, results] = useOptionCalculator({
    optionType: 'call',
    stockPrice: 100,
    strikePrice: 100,
    premium: 5,
    expiration: 30,
    volatility: 30,
  });

  const chartRef = useRef(null);
  const hoveredPoint = useChartInteractions(chartRef);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: parseFloat(value) }));
  };

  const toggleOptionType = () => {
    setState((prevState) => ({
      ...prevState,
      optionType: prevState.optionType === 'call' ? 'put' : 'call',
    }));
  };

  const chartData = generateChartData(state, results);

  return (
    <div className="options-calculator-container">
      <h1 className="calculator-title">Advanced Options Profit Calculator</h1>
      <p className="calculator-description">
        Explore potential profits and losses for call and put options with our state-of-the-art calculator.
      </p>

      <div className="calculator-grid">
        <div className="input-card">
          <h2 className="card-title">Option Parameters</h2>
          <div className="option-type-buttons">
            <button
              className={`option-type-button ${state.optionType === 'call' ? 'active' : ''}`}
              onClick={toggleOptionType}
            >
              Call
            </button>
            <button
              className={`option-type-button ${state.optionType === 'put' ? 'active' : ''}`}
              onClick={toggleOptionType}
            >
              Put
            </button>
          </div>
          <InputField
            label="Stock Price"
            name="stockPrice"
            value={state.stockPrice}
            onChange={handleInputChange}
            icon={IndianRupee}
          />
          <InputField
            label="Strike Price"
            name="strikePrice"
            value={state.strikePrice}
            onChange={handleInputChange}
            icon={IndianRupee}
          />
          <InputField
            label="Option Premium"
            name="premium"
            value={state.premium}
            onChange={handleInputChange}
            icon={IndianRupee}
          />
          <InputField
            label="Days to Expiration"
            name="expiration"
            value={state.expiration}
            onChange={handleInputChange}
            icon={Calendar}
            step="1"
          />
          <InputField
            label="Implied Volatility (%)"
            name="volatility"
            value={state.volatility}
            onChange={handleInputChange}
            icon={Percent}
            step="0.1"
          />
        </div>

        <div className="chart-card">
          <h2 className="card-title">Payoff Chart</h2>
          <div className="chart-container">
            <ResponsiveContainer width="100%" height={400}>
              <LineChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="price" label={{ value: 'Stock Price', position: 'insideBottomRight', offset: -10 }} />
                <YAxis label={{ value: 'Profit/Loss', angle: -90, position: 'insideLeft' }} />
                <Tooltip content={<CustomTooltip />} />
                <Legend />
                <ReferenceLine y={0} stroke="#888" strokeDasharray="3 3" />
                <ReferenceLine x={results.breakeven} stroke="#82ca9d" label="Breakeven" />
                <Line type="monotone" dataKey="profit" stroke="#8884d8" activeDot={{ r: 8 }} />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="results-card">
          <h2 className="card-title">Calculation Results</h2>
          <div className="results-content">
            <ResultItem label="Breakeven Price" value={`Rs ${results.breakeven}`} />
            <ResultItem label="Max Profit" value={results.maxProfit} />
            <ResultItem label="Max Loss" value={`Rs ${results.maxLoss}`} />
            <ResultItem label="Time Value" value={`Rs ${results.timeValue}`} />
            <ResultItem label="Intrinsic Value" value={`Rs ${results.intrinsicValue}`} />
            <ResultItem label="Option Price" value={`Rs ${results.optionPrice}`} />
          </div>
        </div>

        <div className="explanation-card">
          <h2 className="card-title">Strategy Explanation</h2>
          <OptionStrategyExplanation
            optionType={state.optionType}
            stockPrice={state.stockPrice}
            strikePrice={state.strikePrice}
          />
        </div>
      </div>
    </div>
  );
};

// Helper components
const InputField = ({ label, name, value, onChange, icon: Icon, ...props }) => (
  <div className="input-group">
    <label className="input-label" htmlFor={name}>
      {label}
    </label>
    <div className="input-wrapper">
      <Icon className="input-icon" />
      <input
        className="input-field"
        type="number"
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        {...props}
      />
    </div>
  </div>
);

const ResultItem = ({ label, value }) => (
  <div className="result-item">
    <p className="result-label">{label}</p>
    <p className="result-value">{value}</p>
  </div>
);

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`Stock Price : $${label}`}</p>
        <p className="intro">{`Profit/Loss : $${payload[0].value}`}</p>
      </div>
    );
  }
  return null;
};

export default OptionsCalculator;