import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { AiOutlineArrowRight, AiFillStar, AiOutlineCheck } from 'react-icons/ai';
import './Demat.css';

const brokers = [
  {
    name: "Zerodha",
    logo: "https://zerodha.com/static/images/logo.svg",
    url: "https://zerodha.com/?c=BKF711&s=CONSOLE",
    description: "India's largest stock broker offering zero brokerage for investments.",
    features: ["Zero brokerage for delivery trades", "Powerful trading platforms", "Instant withdrawal facility"],
    rating: 4.5,
    pricing: {
      equity: "0.03% or ₹20 per executed order",
      futures: "0.03% or ₹20 per executed order",
      options: "₹20 per executed order",
      currency: "0.03% or ₹20 per executed order"
    },
    platforms: ["Kite Web", "Kite Mobile", "Console"],
    customerSupport: ["Phone", "Email", "Chat"],
    accountOpeningTime: "24 hours",
    isReferral: true
  },
  {
    name: "MasterTrust",
    logo: "https://www.mastertrust.co.in/Images/MasterTrust.png",
    url: "https://www.mastertrust.co.in/products-pricing/MT_CR007860@wp",
    description: "Trusted broker with a wide range of investment products.",
    features: ["Competitive brokerage rates", "Comprehensive research reports", "Dedicated customer support"],
    rating: 4.2,
    pricing: {
      equity: "0.25% or ₹25 per executed order",
      futures: "0.02% or ₹20 per executed order",
      options: "₹50 per executed order",
      currency: "0.02% or ₹20 per executed order"
    },
    platforms: ["MasterTrust Mobile", "MasterTrust Web"],
    customerSupport: ["Phone", "Email", "Branch"],
    accountOpeningTime: "48 hours",
    isReferral: true
  },
  {
    name: "Choice Equity",
    logo: "https://choiceindia.com/assets/images/choice-logo.svg",
    url: "https://choiceindia.com/open-free-demat-account?refercode=QzAwMTQyNTQ=&source=Q0hPSUNFX0NPTk5FQ1Q=",
    description: "Innovative broker offering customized trading solutions.",
    features: ["Advanced trading algorithms", "Mobile trading app", "Educational resources for investors"],
    rating: 4.0,
    pricing: {
      equity: "0.30% or ₹30 per executed order",
      futures: "0.03% or ₹30 per executed order",
      options: "₹30 per executed order",
      currency: "0.03% or ₹30 per executed order"
    },
    platforms: ["Jiffy", "Choice Mobile"],
    customerSupport: ["Phone", "Email", "WhatsApp"],
    accountOpeningTime: "72 hours",
    isReferral: true
  }
];

const StarRating = ({ rating }) => (
  <div className="flex">
    {[1, 2, 3, 4, 5].map((star) => (
      <AiFillStar
        key={star}
        className={`h-5 w-5 ${
          star <= rating ? 'text-yellow-400' : 'text-gray-300'
        }`}
      />
    ))}
  </div>
);

const BrokerCard = ({ broker }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <motion.div
      className="broker-card"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="broker-card-content">
        <img src={broker.logo} alt={`${broker.name} Logo`} className="broker-logo" />
        <h3 className="broker-name">{broker.name}</h3>
        <p className="broker-description">{broker.description}</p>
        <div className="broker-rating">
          <span className="rating-value">{broker.rating}</span>
          <StarRating rating={broker.rating} />
        </div>
        <ul className="broker-features">
          {broker.features.map((feature, index) => (
            <li key={index}><AiOutlineCheck className="feature-icon" /> {feature}</li>
          ))}
        </ul>
      </div>
      <div className="broker-card-footer">
        <button className="learn-more-btn" onClick={() => setIsOpen(true)}>
          Learn More <AiOutlineArrowRight className="arrow-icon" />
        </button>
        <a 
          href={broker.url} 
          target="_blank" 
          rel="noopener noreferrer" 
          className={`open-account-btn ${broker.isReferral ? 'referral' : ''}`}
        >
          Open Account
          {broker.isReferral && <span className="referral-tag">Referral</span>}
        </a>
      </div>

      <AnimatePresence>
        {isOpen && (
          <motion.div 
            className="dialog-overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setIsOpen(false)}
          >
            <motion.div 
              className="dialog-content"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
              onClick={e => e.stopPropagation()}
            >
              <h2 className="dialog-title">{broker.name}</h2>
              <div className="dialog-body">
                <h4>Key Features:</h4>
                <ul>
                  {broker.features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul>
                <h4>Pricing:</h4>
                <ul>
                  {Object.entries(broker.pricing).map(([key, value]) => (
                    <li key={key}><strong>{key}:</strong> {value}</li>
                  ))}
                </ul>
                <h4>Trading Platforms:</h4>
                <ul>
                  {broker.platforms.map((platform, index) => (
                    <li key={index}>{platform}</li>
                  ))}
                </ul>
                <h4>Customer Support:</h4>
                <ul>
                  {broker.customerSupport.map((support, index) => (
                    <li key={index}>{support}</li>
                  ))}
                </ul>
                <h4>Account Opening Time:</h4>
                <p>{broker.accountOpeningTime}</p>
              </div>
              <button className="dialog-close" onClick={() => setIsOpen(false)}>Close</button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

const Demat = () => {
  return (
    <div className="demat-container">
      <h1 className="demat-title">Compare Top Demat Account Providers</h1>
      <p className="demat-subtitle">Find the best broker for your investment needs</p>
      <div className="broker-grid">
        {brokers.map((broker, index) => (
          <BrokerCard key={index} broker={broker} />
        ))}
      </div>
    </div>
  );
};

export default Demat;