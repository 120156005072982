import React from 'react';
import './About.css';
import {  FaYoutube, FaWhatsapp, FaFacebook, FaInstagram } from 'react-icons/fa';
import founderImage from "../Images/shivam.jpg"
import Footer from './Footer';


const About = () => {
  return (
    <>
    
    <div className="about-page">
    <header className="about-page__hero">
  <h1 className="about-page__title">Welcome to Rahar Stock Market Academii</h1>
</header>
      
      <section className="about-page__vision slide-in-left">
        <h2 className="about-page__section-title">Our Vision</h2>
        <p>
          At Rahar Stock Market Academii, we strive to be a leading provider of market education and resources. 
          We believe that everyone should have access to the tools and knowledge necessary to make informed 
          investment decisions. Our comprehensive courses are designed to empower individuals to take control 
          of their financial future through the power of understanding and responsible investing.
        </p>
      </section>

      <section className="about-page__founder slide-in-right">
        <h2 className="about-page__section-title">Our Founder</h2>
        <div className="about-page__founder-content">
          <div className="about-page__founder-image-container">
            <img src={founderImage} alt="Shivam Rahar" className="about-page__founder-image" />
          </div>
          <p>
          Welcome to Rahar Stock Market Academii, where we're not just about education; we're about transformation. With a mission to empower individuals with the knowledge and skills needed to thrive in the world of investing, we've curated a platform that goes beyond traditional learning.

Led by Shivam Rahar, a seasoned professional with a diverse background in software development, civil services, and economics, our Academy offers a unique blend of expertise and insight. Shivam's journey, marked by a relentless pursuit of excellence and a thirst for knowledge, serves as the guiding light for our students.

At Rahar Stock Market Academii, we are passionate about educating individuals on the art of investing. Our team of experienced professionals has a wealth of knowledge in portfolio management and investment strategies. We specialize in providing comprehensive courses that cater to both beginner and advanced investors. Our personalized approach ensures that our clients receive tailored services that align with their unique investment goals. With a focus on data-driven strategies and expert recommendations, we empower our clients to make informed decisions that yield favorable results. Join us on the journey of financial growth and success.
          </p>
        </div>
      </section>

      <section className="about-page__approach ">
        <h2 className="about-page__section-title">Our Approach</h2>
        <ul className="about-page__approach-list">
          <li>Comprehensive courses for beginners and advanced investors</li>
          <li>Personalized services tailored to individual investment goals</li>
          <li>Data-driven strategies and expert recommendations</li>
          <li>Focus on portfolio management and investment strategies</li>
          <li>Empowering clients to make informed decisions</li>
        </ul>
      </section>

      <section className="about-page__contact slide-in-bottom">
        <h2 className="about-page__section-title">Join Our Journey</h2>
        <p>
          At Rahar Stock Market Academii, we're not just about education; we're about transformation. 
          With a mission to empower individuals with the knowledge and skills needed to thrive in the 
          world of investing, we've curated a platform that goes beyond traditional learning. Join us 
          on the journey of financial growth and success.
        </p>
        <p className="about-page__email"><a href="mailto:shiivamrahhar@gmail.com">shiivamRahhar@gmail.com</a></p>
        <div className="about-page__social-links">
  <a href="https://www.youtube.com/channel/UC21zJ_6azEJARDWbnAOBo0" className="about-page__social-icon" target="_blank" rel="noopener noreferrer"><FaYoutube /></a>
  <a href="https://www.instagram.com/raharstockmarketAcademy/?hl=ens" className="about-page__social-icon" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
  <a href={`https://wa.me/917740047670?text=${encodeURIComponent("Hello! I have a question about your services.")}`} className="about-page__social-icon" target="_blank" rel="noopener noreferrer"><FaWhatsapp /></a>
  <a href="https://www.facebook.com/raharstockmarketAcademy" className="about-page__social-icon" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
</div>

      </section>
    </div>
    <Footer />
    </>
  );
};

export default About;
